<template>
  <v-app-bar
    absolute
    flat
    height="75"
    app
    :color="pagestyles.background"
    dark
  >
    <v-app-bar-nav-icon
      v-if="isAuthenticated"
      @click.stop="setDrawer(!drawer)"
    />
    <v-container class="px-2 d-flex align-center justify-start">
      <div class="ml-4 .d-none hidden-xs-only">
        <div class="font-weight-bold display-3">
          {{ appTitle }}
        </div>
        <div class="subtitle-1">
          {{ appSubTitle }}
          <span
            v-if="environment !== 'Production'"
            class="font-weight-bold red"
          >
            [ {{ environment }} ]
          </span>
        </div>
      </div>
      <v-spacer />
      <v-btn
        v-if="!isAuthenticated"
        min-height="48"
        min-width="40"
        text
        to="/register"
      >
        <v-icon size="25">
          mdi-account-multiple-plus
        </v-icon>
        <span class="hidden-sm-and-down">
          Register
        </span>
      </v-btn>
      <v-btn
        v-if="!isAuthenticated"
        min-height="48"
        min-width="40"
        text
        @click="onLoginClick"
      >
        <v-icon size="25">
          mdi-fingerprint
        </v-icon>
        <span class="hidden-sm-and-down">
          Login
        </span>
      </v-btn>
    </v-container>
  </v-app-bar>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'
  import customColors from '@/styles/appcolors'
  export default {
    name: 'ApplicationCoreAppBar',
    data: function () {
      return {
        appTitle: process.env.VUE_APP_TITLE,
        appSubTitle: process.env.VUE_APP_SUB_TITLE,
        environment: process.env.VUE_APP_ENVIRONMENT,
        pagestyles: {
          background: customColors.MainPage.background,
        },
        profile: [
          {
            title: 'Profile',
          },
          {
            title: 'Settings',
          },
          {
            divider: true,
          },
          {
            title: 'Log out',
          },
        ],
      }
    },
    computed: {
      ...mapState(['drawer']),
      isAuthenticated: function () {
        return this.$msal.isAuthenticated()
      },
      userProfile () {
        return this.msal.user
      },
      avatarImage () {
        let uniqueName = this.msal.user.userName
        uniqueName = uniqueName.replace('@emn.com', '')
        const avatarURL =
          'http://badgepics.emn.com/small/' + uniqueName.substr(1) + '.jpg'
        // const avatarURL = 'http://badgepics.emn.com/small/330016.jpg'
        return avatarURL
      },
    },
    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      onLoginClick: function () {
        this.$msal.signIn()
      },
      onMenuItemClick: function (itemTitle) {
        switch (itemTitle) {
          case 'Profile':
            alert('show profile')
            break
          case 'Settings':
            alert('update settings')
            break
          case 'Log out':
            this.$msal.signOut()
            break
        }
      },
    },
  }
</script>
